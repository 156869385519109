import React, {useState} from 'react';
import { useStaticQuery, graphql, navigate } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { css } from "@emotion/react";
import 'react-accessible-accordion/dist/fancy-example.css';
import Icon from '../../atoms/Icon/Icon';
import ValuesFilter from '../../organisms/ValuesFilter/ValuesFilter';
// import { StaticImage } from "gatsby-plugin-image"
import iconoSelect from "../../../images/icono-select.png";

export default function BuscadorProyectos({event}) {

  const data = useStaticQuery(graphql`
    {
      wordpress {
        distritos {
          nodes {
            name
            proyectos {
              nodes {
                title
              }
            }
          }
        }
        dormitorios {
          nodes {
            name
          }
        }
        etapas {
          nodes {
            name
          }
        }
      }
    }
  `)

  const distritos = data.wordpress.distritos.nodes;
  const dormitorios = data.wordpress.dormitorios.nodes;
  const etapas = data.wordpress.etapas.nodes;
  let arrDistritos = [];
  let arrDormitorios = [];
  let arrEtapas = [];

  const contentSearch = css`
    padding-top: calc(100vw * (calc(39 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    width: calc(100vw * (calc(841 / var(--width_base))));
    display: flex;
  `;


  const searchItem = css`
    width: 33.33%;
    &:not(:last-of-type){
      margin-right: calc(100vw * (calc(93 / var(--width_base))));
    }
  `;

  const searchControl = css`
    position: relative;
    border-top: none;
    border-right: none;
    border-left: none;
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    line-height: calc(100vw * (calc(34 / var(--width_base))));
    font-style: italic;
    font-family: "Source Sans Pro", sans-serif;
    padding-bottom: calc(100vw * (calc(5 / var(--width_base))));
    border-bottom: calc(100vw * (calc(2 / var(--width_base)))) solid;

    &:hover .searchControlList{
      display: block;
    }

    //&:hover .search__icon img{
    &:hover .search__icon {
      transform: rotate(180deg) !important;
    }

    & img.search__icon{
      position: absolute;
      right: 0;
      top: calc(100vw * (calc(5 / var(--width_base))));
      z-index: 1;
      object-fit: contain;
    }

    //& .search__icon.gatsby-image-wrapper{
    & .search__icon{
      overflow: initial!important;
    }
    
    & .search__icon, & .search__icon img{
      width: calc(100vw * (calc(18 / var(--width_base))));
      height: calc(100vw * (calc(15 / var(--width_base))));
      line-height: 0;
    }
    
    & .searchControlList{
      background-color: white;
      display: none;
      margin: 0;
      position: absolute;
      left: calc(100vw * (calc(-23 / var(--width_base))));
      right: 0;
      top: 100%;
      z-index: 1;
      padding-left: calc(100vw * (calc(23 / var(--width_base))));
      padding-right: calc(100vw * (calc(23 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
      padding-top: calc(100vw * (calc(25 / var(--width_base))));
    }

    & .searchControlList li{
      padding-top: calc(100vw * (calc(10 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
    
    & .searchControlLabel{
      cursor: pointer;
      text-transform: uppercase;
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      line-height: calc(100vw * (calc(25 / var(--width_base))));
      font-style: initial;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    & .searchControlLabel > div{
      margin-right: calc(100vw * (calc(13 / var(--width_base))));
      flex-shrink: 0;
    }

    & .searchControlLabel input{
      //margin-right: calc(100vw * (calc(13 / var(--width_base))));
      display: none;
    }
  `;

  const accordinMovilCss = css`
    width: 100%;
    border-bottom: calc(100vw * (calc(2 / var(--width_base)))) solid black;
    margin-bottom: calc(100vw * (calc(40 / var(--width_base)))); 
  `;

  const listCheckboxCss = css`
    display: none;
  `;

  const listCheckboxLabelCss = css`
    display: flex;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(13 / var(--width_base))));
    align-items: center;
  `;

  const accordionButtonCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: calc(100vw * (calc(15 / var(--width_base))));
    padding-left: calc(100vw * (calc(15 / var(--width_base))));
    padding-top: calc(100vw * (calc(11 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(14 / var(--width_base))));
    border-top: calc(100vw * (calc(2 / var(--width_base)))) solid black;
    font-style: italic;
    &:focus{
      outline: none;
    }

    &[aria-expanded="true"] .icon-arrow{
      transform: rotate(180deg);
    }
  `;

  const accordionPanelCss = css`
    padding-top: calc(100vw * (calc(5 / var(--width_base))));
    padding-right: calc(100vw * (calc(15 / var(--width_base))));
    padding-left: calc(100vw * (calc(15 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(13 / var(--width_base))));
  `;

  let urlPageSearch = "";
  let params = "";
  let [distritoUrl, setDistritoUrl] = useState("");
  let [dormitorioUrl, setDormitorioUrl] = useState("");
  let [etapaUrl, setEtapaUrl] = useState("");

  if((typeof window !== `undefined`)){
    urlPageSearch = window.location.search;
    params = new URLSearchParams(urlPageSearch);
    if(params.get("distrito")){
      setDistritoUrl(params.get("distrito"));
      arrDistritos = params.get("distrito").split("|");
    }else{
      setDistritoUrl("");
    }

    if(params.get("dormitorio")){
      setDormitorioUrl(params.get("dormitorio"));
      arrDormitorios = params.get("dormitorio").split("|");
    }else{
      setDormitorioUrl("")
    }

    if(params.get("etapa")){
      setEtapaUrl(params.get("etapa"));
      arrEtapas = params.get("etapa").split("|");
    }else{
      setEtapaUrl("");
    }
  }

  const onChangeDistritos = (event) =>{
    const seccionEle = document.getElementById("banner-proyecto");
    if(seccionEle) seccionEle.classList.remove("is-visible");
    const distritoSelect = event.target.value;
    if(!arrDistritos.includes(distritoSelect)){
      arrDistritos.push(distritoSelect);
    }else{
      arrDistritos = arrDistritos.filter((distrito)=> distrito !== distritoSelect);
    }
    setDistritoUrl(distritoSelect);
    navigate(`/proyectos/?distrito=${arrDistritos.join("|")}&dormitorio=${arrDormitorios.join("|")}&etapa=${arrEtapas.join("|")}`);
    setTimeout(()=>{
      if(seccionEle) seccionEle.classList.add("is-visible");
    },500)
  }

  const onChangeDormitorios = (event) =>{
    const seccionEle = document.getElementById("banner-proyecto");
    if(seccionEle) seccionEle.classList.remove("is-visible");
    const dormitorioSelect= event.target.value;
    if(!arrDormitorios.includes(dormitorioSelect)){
      arrDormitorios.push(dormitorioSelect);
    }else{
      arrDormitorios = arrDormitorios.filter((dorm)=>  dorm !== dormitorioSelect);
    }
    setDormitorioUrl(dormitorioSelect);
    navigate(`/proyectos/?distrito=${arrDistritos.join("|")}&dormitorio=${arrDormitorios.join("|")}&etapa=${arrEtapas.join("|")}`);
    setTimeout(()=>{
      if(seccionEle) seccionEle.classList.add("is-visible");
    },500)
  }

  const onChangeEtapas = (event) =>{
    const seccionEle = document.getElementById("banner-proyecto");
    if(seccionEle) seccionEle.classList.remove("is-visible");
    const etapaSelect = event.target.value;
    if(!arrEtapas.includes(etapaSelect)){
      arrEtapas.push(etapaSelect);
    }else{
      arrEtapas = arrEtapas.filter((etapa)=>  etapa !== etapaSelect);
    }
    setEtapaUrl(etapaSelect);
    navigate(`/proyectos/?distrito=${arrDistritos.join("|")}&dormitorio=${arrDormitorios.join("|")}&etapa=${arrEtapas.join("|")}`);

    setTimeout(()=>{
      if(seccionEle) seccionEle.classList.add("is-visible");
    },500)
  }

  return (
    <>
      <div className="only-desk">
        <div css={contentSearch}>
          <div css={searchItem} className="search__item">
            <div css={searchControl}>
              Distritos
              {/*<StaticImage*/}
              {/*  src="../../../images/icono-select.png"*/}
              {/*  quality={95}*/}
              {/*  formats={["auto", "webp", "avif"]}*/}
              {/*  alt="Select icon"*/}
              {/*  objectFit="contain"*/}
              {/*  className="search__icon"*/}
              {/*/>*/}
              <img src={iconoSelect} alt="Select icon" className="search__icon" />
              <ul className="searchControlList">
                {
                  distritos && distritos.map((distrito,i )=>(
                    <>
                      {
                        (distrito?.proyectos?.nodes?.length) ?
                        <li key={i}><label className="searchControlLabel">{(arrDistritos.includes(distrito.name)) ? <Icon type="check"/> :<Icon type="check-vacio"/>}<input type="checkbox" value={distrito.name} onChange={onChangeDistritos} />{distrito.name}</label></li>
                          :
                          <></>
                      }
                    </>
                  ))
                }
              </ul>
            </div>
          </div>
          <div css={searchItem} className="search__item">

            <div css={searchControl}>
              Dormitorios
              {/*<StaticImage*/}
              {/*  src="../../../images/icono-select.png"*/}
              {/*  quality={95}*/}
              {/*  formats={["auto", "webp", "avif"]}*/}
              {/*  alt="Select icon"*/}
              {/*  objectFit="contain"*/}
              {/*  className="search__icon"*/}
              {/*/>*/}
              <img src={iconoSelect} alt="Select icon" className="search__icon" />
              <ul className="searchControlList">
                {
                  dormitorios && dormitorios.map((dormitorio,i )=>(
                      <li key={i}><label className="searchControlLabel">{(arrDormitorios.includes(dormitorio.name)) ? <Icon type="check"/> :<Icon type="check-vacio"/>}<input type="checkbox" value={dormitorio.name} onChange={onChangeDormitorios} />{dormitorio.name}</label></li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div css={searchItem} className="search__item">

            <div css={searchControl}>
              Etapas
              {/*<StaticImage*/}
              {/*  src="../../../images/icono-select.png"*/}
              {/*  quality={95}*/}
              {/*  formats={["auto", "webp", "avif"]}*/}
              {/*  alt="Select icon"*/}
              {/*  objectFit="contain"*/}
              {/*  className="search__icon"*/}
              {/*/>*/}
              <img src={iconoSelect} alt="Select icon" className="search__icon" />
              <ul className="searchControlList">
                {
                  etapas && etapas.map((etapa,i )=>(
                    <li key={i}><label className="searchControlLabel">{(arrEtapas.includes(etapa.name)) ? <Icon type="check"/> :<Icon type="check-vacio"/>}<input type="checkbox" value={etapa.name} onChange={onChangeEtapas} /> {etapa.name}</label></li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
        {(distritoUrl || dormitorioUrl || etapaUrl) && <ValuesFilter distritos={arrDistritos} dormitorios={arrDormitorios} etapas={arrEtapas}/>}
      </div>
      <div className="only-mov">
        <Accordion css={accordinMovilCss}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton css={accordionButtonCss}>Distritos <Icon type="arrow-select" classEle="icon-arrow"/></AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel css={accordionPanelCss}>
              {
                distritos && distritos.map((distrito,i )=>(
                  <>
                    {
                      (distrito?.proyectos?.nodes?.length) ?
                        <label css={listCheckboxLabelCss}>
                          {(arrDistritos.includes(distrito.name)) ? <Icon type="check"/> :<Icon type="check-vacio"/>}
                          <input css={listCheckboxCss} type="checkbox" value={distrito.name} onChange={onChangeDistritos}/>
                          {distrito.name}
                        </label>
                        :
                        <span/>
                    }
                  </>
                ))
              }
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton css={accordionButtonCss}>Dormitorios <Icon type="arrow-select" classEle="icon-arrow"/></AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel css={accordionPanelCss}>
              {
                dormitorios && dormitorios.map((dormitorio,i )=>(
                  <label css={listCheckboxLabelCss}>
                    {(arrDormitorios.includes(dormitorio.name)) ? <Icon type="check"/> :<Icon type="check-vacio"/>}
                    <input css={listCheckboxCss} type="checkbox" value={dormitorio.name} onChange={onChangeDormitorios}/>
                    {dormitorio.name}
                  </label>
                ))
              }
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton css={accordionButtonCss}>Etapa <Icon type="arrow-select" classEle="icon-arrow"/></AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel css={accordionPanelCss}>
              {
                etapas && etapas.map((etapa,i )=>(
                  <label css={listCheckboxLabelCss}>
                    {(arrEtapas.includes(etapa.name)) ? <Icon type="check"/> :<Icon type="check-vacio"/>}
                    <input css={listCheckboxCss} type="checkbox" value={etapa.name} onChange={onChangeEtapas}/>
                    {etapa.name}
                  </label>
                ))
              }
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );

}